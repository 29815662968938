.project-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
}

.loading-message,
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  animation: 0.45s ease-out 0s 1 slidein;
  width: 66rem;
  height: 55rem;
  background-color: var(--dark-blue-color);
  justify-content: space-between;
}

.modal-grid {
  display: grid;
  grid-template-columns: auto auto;
}

@keyframes slidein {
  from {
    top: -50%;
  }
  to {
    top: 50%;
  }
}

.modal-second-column {
  display: flex;
  flex-direction: column;
}

.modal-description {
  overflow-y: auto;
  max-width: 100%;
  max-height: 40rem;
  padding-right: 6rem;
}

.modal-title .icon-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-title h1 {
  padding-top: 4rem;
  word-wrap: break-word;
}

.modal-buttons {
  padding: 1rem 5rem 1rem 0;
  justify-content: flex-end;
}

.modal-buttons .button {
  margin-left: 1rem; /* Add spacing between the buttons */
}

#circular-project-image {
  height: 20rem;
  width: 20rem;
  background-image: 'https://www.amrita.edu/sites/default/files/news-images/new/news-events/images/l-nov/grass.jpg';
}

.technologies {
  padding: 4rem 2rem 2rem 2rem;
}

.technologies ul {
  list-style: none; /* Remove list bullets */
  padding: 0;
}

.technologies li {
  padding: 0.3rem 0;
}

/* ---- MEDIA QUERIES ------ */

@media (max-width: 66rem) {
  .modal {
    width: 100%;
    height: 100%;
  }

  #circular-project-image {
    height: 15rem;
    width: 15rem;
  }
}

/* Scroll bar */

/* width */
::-webkit-scrollbar {
  width: 0.3rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


/* Media queries */

@media screen and (max-width: 40rem) {
  .modal-title .icon-button {
    /* Put button in right corner above title */
    position: relative;
    align-self: flex-end;
    padding: 1rem 0;
    top: 0 !important;
    right: 0 !important;
  }

  .modal {
    animation: none !important;
    overflow-y: auto;
  }

  .modal-title h1 {
    padding-top: 0 !important;
    padding-right: 0 !important;
  }

  .modal-grid {
    display: flex;
    flex-direction: column-reverse; /* To make technologies come after description */
  }

  .modal .technologies,
  .modal-second-column {
    padding: 1rem 2rem;
  }

  .modal-description {
    height: auto;
    max-height: none; /* Remove scroll on mobile */
    padding: 0 !important;
  }

  #circular-project-image {
    display: none; /* Remove image on mobile */
  }
}

@media screen and (max-width: 55rem) {
  .modal-description {
    padding-right: 2rem;
  }

  .modal-buttons {
      padding-right: 2rem;
  }
}
