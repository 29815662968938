.home-section {
  height: 100vh;
}

.welcome-text {
  position: relative;
  top: 40%;
  display: inline-block;
}

.home-section h1 {
  display: inline-block; /* To ensure blue line only follows the H1 and not the p as well */
  font-size: 10rem;
  line-height: 0.9em; /* Font originally has a lot of whitespace */
  font-weight: bold;
  margin: 0.1em 0;
}

.home-section h1:after {
  display: block;
  width: 100%;
  border-top: 0.1em solid var(--light-blue-color);
  content: '';
  animation-name: bluebar-slidein;
  animation-duration: 1s;
}

@keyframes bluebar-slidein {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.home-section p {
  font-size: var(--section-title-size);
  line-height: 0.9em; /* Font originally has a lot of whitespace */
  margin: 0;
}