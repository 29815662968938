.contact-section {
  position: relative;
  height: 24rem;
}

.icons-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
}
