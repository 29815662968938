.navbar-element {
  color: #9b9a9a;
  text-align: end;
  font-family: inherit;
  margin: 1.75em 0;
}

.navbar-element.active {
  color: var(--white-text-color);
  text-shadow: 0 0 0.01rem var(--white-text-color);
}

.navbar-element:hover {
  cursor: pointer;
}

.navbar {
  position: fixed;
  top: 50%;
  right: 1.5rem;
  max-height: 40%;
  transform: translate(
    0,
    -50%
  ); /* To center the contents, doesn't support IE8 and below */

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  list-style: none; /* Remove list bullets */
}

@media (max-width: 80em) {
  .navbar {
    flex-direction: row;
    align-items: center;
    padding: 1em 0;
    width: 100%;
    z-index: 1;
    background-color: var(--dark-grey-color);
    box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.7);
    border-bottom: var(--light-blue-color);
    border-bottom-style: groove;

    /* Removes previous modifications */
    margin: 0;
    top: 0;
    right: 0;
    transform: none;
  }

  .navbar-element {
    margin: 0 1rem;
  }
}

@media (max-width: 25em) {
  .navbar-element {
    margin: 0 0.5rem; /* TODO: Make hamburger menu */
  }
}
