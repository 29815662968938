.scroll-arrow {
  position: absolute;
  top: 93%;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.5));
}

.scroll-arrow line {
    stroke: var(--light-blue-color);
    stroke-width: 5;
    transition-property: stroke;
    transition-duration: 200ms;
}

.scroll-arrow:hover {
  cursor: pointer;  
}

.scroll-arrow:hover line {
    stroke: var(--light-blue-color-highlighted);
    stroke-width: 5.5;
}