.unity .icon-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 105;
}

.fullscreen-text {
  position: absolute;
  top: 0;
  left: 1rem;
  z-index: 105;
}

.modal.unity {
  width: 66rem;
  height: auto !important;
  justify-content: space-between;
}

.modal.unity .video-player {
  margin: 0;
}

@media (max-width: 66rem) {
  .modal.unity {
    width: 100%;
  }
}
