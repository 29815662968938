.icon-button {
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: rgb(150, 150, 150);
  cursor: pointer;
}
.icon-button.small {
    width: 2rem;
    height: 2rem;
}
.icon-button.medium {
    width: 3rem;
    height: 3rem;
}
.icon-button.large {
    width: 4rem;
    height: 4rem;
}

.icon-button svg {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1; /* To make it above the background */
}

.background {
  background-color: transparent;
  position: absolute;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
}

.background.hovered {
  background-color: rgba(128, 128, 128, 0.7);
  animation-name: onHover;
  animation-duration: 0.2s;
}

@keyframes onHover {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
