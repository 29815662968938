.video-player {
  display: block;
  position: relative;
  width: 100%;
  padding: 0% 0% 56.25%; /* 16:9 ratio */
  margin: 1rem 0;
}

.video-player iframe {
  position: absolute;
  height: 100%;
  width: 100%;
}
