a {
  text-decoration: none;
}
.button {
  -webkit-appearance: none;
  -webkit-border-radius: none;
  border-radius: 1em;
  color: initial;
  border-width: 0;
  padding: 0.75em 2em;
  font-size: 1rem;
  font-family: inherit;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.7);
}

.button:active {
  color: initial;
}

.button:hover {
  text-decoration: underline;
}

.button.outlined {
  border-style: solid;
  border-width: 0.2rem;
  outline: none;
  background-color: transparent !important;
  color: white;
}

.button.outlined {
  color: white;
}

.button.none {
  background-color: transparent;
  color: white /* For underline color */;
}

.button.none {
  color: white;
}

.button.none:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.button.grey {
  background-color: var(--light-grey-color);
  border-color: var(--light-grey-color);
}

.button.grey:hover {
  background-color: var(--light-grey-color-highlighted);
  border-color: var(--light-grey-color-highlighted);
}

.button.green {
  background-color: var(--green-color);
  border-color: var(--green-color);
}

.button.green:hover {
  background-color: var(--green-color-highlighted);
  border-color: var(--green-color-highlighted);
}
