body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
}
* {
  box-sizing: border-box;
}
:root {
  --bg-color: #2a2a2e;
  --white-text-color: #ffffff;
  --light-blue-color: #a4b8ff;
  --light-blue-color-highlighted: #bdc7ee;
  --dark-blue-color: #030013;
  --light-grey-color: #c4c4c4;
  --light-grey-color-highlighted: #e9e9e9;
  --dark-grey-color: #232324;
  --green-color: #5cff5c;
  --green-color-highlighted: #80ff80;
  --section-title-size: 5rem;
}

body {
  background-color: var(--bg-color);
  color: var(--white-text-color);
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
}

section {
  width: min(95%, 75rem);
  margin: 0 auto;
}

.pointer-hover:hover {
  cursor: pointer;
}

.section-title {
  padding-top: 0.48em;
  padding-bottom: 0.12em;
  text-align: center;
  font-size: var(--section-title-size);
}

.section-title:after {
  display: block;
  width: 1.5ch;
  margin: 0.1em auto;
  border-top: 0.08em solid var(--light-blue-color);
  content: '';
}


h1 {
  font-size: 5rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 1.5rem;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.split {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.split > * {
  flex-basis: 100%;
}

.bold {
  font-weight: bolder;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.rounded-corner {
  border-radius: 1.5rem;
}

/* --------- MEDIA QUERIES ---------- */
@media (max-width: 45em) {
  :root {
    --section-title-size: 3rem !important; /* Will be overriden by standard root elsewise */
  }

  .home-section h1 {
    font-size: 6rem;
  }

  .split {
    flex-direction: column;
  }

  .button {
    width: auto !important;
  }

  .project-card {
    min-height: 0 !important;
  }

  .project-card .info {
    order: 1 !important;
  }

  .project-card .text {
    margin: 0.8rem 0 0 0;
    padding: 0 1em;
  }

  .project-card .button-container {
    padding-block: 1.6rem;
    padding-left: 1.5rem;
  }

  .project-card .button-container .button {
    padding: 0.5em 1.5em;
  }

  .project-card .project-image {
    border-radius: 1.5rem 1.5rem 0 0 !important;
  }

  .play-button {
    display: none; /* Can't play on mobile */
  }
}

/* --------------- FONTS ---------- */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fBBc4.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.roboto {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v22/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58a-wg.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.quicksand {
  font-family: 'Quicksand', Helvetica, sans-serif;
}
