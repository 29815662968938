.project-card {
  text-align: left;
  align-items: stretch;

  margin: 3rem auto;
  width: 100%;
  max-width: 70rem;
  min-height: 30rem;
  font-size: 1.5rem;

  background-color: var(--dark-blue-color);
  border-radius: 1.5rem;
}

.project-card .info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.project-card .info > * {
  padding-left: 5rem;
}

.project-card .text {
  padding-top: 5rem;
  padding-right: 5rem;
  max-width: 90%;
}

.project-card:nth-child(even) .info {
  order: 2;
}

.project-card p {
  margin-top: 0.5rem;
}

.project-card .button-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding-bottom: 5rem;
  width: 100%;
}

.button-container .button {
  width: 100%;
  max-width: 10rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.project-card img {
  max-width: 100%;
  overflow: hidden;
  padding: 2rem;
}

.project-card .project-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 1.5rem 1.5rem 0; /* So only one side is rounded */
}

.project-card:nth-child(even) .project-image {
  border-radius: 1.5rem 0 0 1.5rem;
}

/* ---------- PROJECT IMAGES ----------- */

.khabu-image {
  background-image: linear-gradient(
    150deg,
    rgb(59, 53, 53) -20%,
    rgb(163, 120, 120)
  );
}

.galaxy-shooter-image {
  background-image: linear-gradient(140deg, #02264b, #5b96d6);
}

.this-page-image {
  background-image: linear-gradient(150deg, #3f3f3f -20%, #202022);
}

.elzar-escape-image {
  background-image: linear-gradient(150deg, #f0da91 -20%, #4b5033);
}